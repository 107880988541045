var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("c-dialog", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.formLoading,
        expression: "formLoading",
      },
    ],
    attrs: { title: "选择分类", width: 500, showDialog: _vm.openCategory },
    on: {
      "update:showDialog": function ($event) {
        _vm.openCategory = $event
      },
      "update:show-dialog": function ($event) {
        _vm.openCategory = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticStyle: { height: "230px" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "80px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "分类", prop: "categoryId" } },
                      [
                        _c("Treeselect", {
                          attrs: {
                            options: _vm.mallCategoryTreeOptions2,
                            "show-count": true,
                            placeholder: "请选择分类",
                            normalizer: _vm.normalizer1,
                            open: _vm.getMallCategory,
                            maxHeight: "180",
                          },
                          on: { select: _vm.select },
                          model: {
                            value: _vm.form.categoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "categoryId", $$v)
                            },
                            expression: "form.categoryId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttonList",
        fn: function () {
          return [
            _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v("确 定")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }