<template>
  <div class="container x-start" v-loading="loading">
    <!-- todo -->
    <div class="formBox" style="width: 100%">
      <!-- :disabled= 'disableOperate' -->
      <topOperatingButton :isShowImg="true" :isShowUnit="Boolean(!['dianCan', 'cake'].includes($route.query.type))" :isExamineBtn="false" :showAudit="false" :isSubmitAddBtn="false" :isAuditBillBtn="false"
        :isStopBtn="false" id="topOperatingButton" ref="topOperatingButton" @submitForm="submit(false)"
        @getQuit="getQuit" @synchronousImg="synchronousImg" @synchronousUnit="synchronousUnit">
      </topOperatingButton>
      <el-form :model="form" :rules="rules" ref="form" label-width="120px">
        <cardTitleCom cardTitle="基本信息">
          <template #cardContent>
            <div>
              <div class="padd15 x-bc">
                <div style="
                    overflow-x: hidden;
                    width: 100%;
                    box-sizing: border-box;
                  ">
                  <el-form-item label="商品图片" prop="goodsUrl">
                    <AccessoryUpload :title="''" :limit="Number(6)" listType="picture-card" :fileSize="1"
                      @getFileItems="getGoodsUrls" @delFileItems="delGoodsUrl" :fileList="fileList"
                      :noneBtnImg="form.goodsImages.length >= 6" @sortChange='sortChange'></AccessoryUpload>
                  </el-form-item>
                  <el-form-item label="上传视频" prop="goodsUrl" v-if="!['dianCan', 'cake'].includes($route.query.type)">
                    <!-- <up-load-video
                      :video="form.video"
                      :videoBaseUrl="videoBaseUrl"
                      :numLimit="1"
                      @deleteVideo="deleteVideo"
                      @getVideo="getVideo"
                    ></up-load-video> -->
                    <OssUpload v-model="form.video.accessUrl" @change="changeOss" :option="{
                        listType: 'video',
                        type: 'mp4',
                        showList: false,
                        clear:true,
                        size: '20mb'
                      }" />
                  </el-form-item>
                  <el-form-item label="商品编码" prop="goodsNo" class="w33">
                    <el-input disabled v-model="form.goodsNo" placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="商品名称" prop="goodsName" class="w33">
                    <el-input v-model="form.goodsName" disabled placeholder=""></el-input>
                  </el-form-item>
                  <el-form-item label="商品分类" prop="categoryId" class="w33">
                    <Treeselect style="z-index: 666" v-model="form.categoryId" :options="categoryTreeOptions"
                      :show-count="true" placeholder="" :normalizer="normalizer1" :open="getTreeselect"
                      @select="select" />
                  </el-form-item>

                  <el-form-item label="商品品牌" prop="brandId" class="w33"
                    v-if="$route.query.type == 'cake'||$route.query.type == 'dianCan'">
                    <el-input v-model="form.brandName" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="商品品牌" prop="brandId" class="w33" v-else>
                    <el-select v-model="form.brandId" disabled clearable placeholder="" element-loading-text="数据加载中">
                      <el-option v-for="item in brandData" :key="item.brandId" :label="item.brandName"
                        :value="item.brandId" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="商品排序" v-if="$route.query.type!='diancan'" prop="sortNo" class="w33">
                    <el-input v-model.number="form.sortNo" placeholder="输入商品排序" maxlength="5"></el-input>
                  </el-form-item>
                  <el-form-item label="商品卖点" prop="goodsSpec" class="w33" v-if="$route.query.type != 'dianCan'">
                    <el-input maxlength="60" v-model="form.sellingPoints" placeholder="输入商品卖点"></el-input>
                  </el-form-item>
                  <el-form-item label="规格列表" prop="goodsUnit" class="specList">
                    <div class="goodsSpecList" style="overflow: auto">
                      <div class="specItem">
                        <goodsSpecItem :type="$route.query.type" :goodsUnit="form.goodsUnit"
                          :basicGoodsUnit="form.basicGoodsUnit" @handleAddMachiningGroup="handleAddMachiningGroup"
                          @handleAddMachining="handleAddMachining" :machiningData="allMachiningGroupData"
                          @delGoodsSpec="handleDelGoodsSpec" @setUnit="setUnit" :unitList="unitList"
                          :referUnitData="referUnitData" @refreshReferUnitData="refreshReferUnitData"
                          @openSelectGoods="openSelectGoods" @handleCollocationTableRow="handleCollocationTableRow"
                          @collocationTableKeyup="collocationTableKeyup" @setShopPriceItems="setShopPriceItems"
                          :updateDisabled="form.isEnableShopPrice"></goodsSpecItem>
                      </div>
                      <!-- <div class="btn marT10">
                                                <el-button size="mini" class="el-icon-plus"
                                                    @click="handleAddGoodsSpec">新建规格</el-button>
                                            </div> -->
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div class="padd15" v-if="$route.query.type != 'dianCan'">
                <el-form-item>
                  <el-checkbox v-if="$route.query.type != 'cake'" v-model="form.isEnableShopPrice" @change="">商城门店价格策略
                  </el-checkbox>
                  <el-checkbox v-else v-model="form.isEnableShipPrice" @change="">商城门店价格策略
                  </el-checkbox>
                  <el-table v-if="form.isEnableShopPrice || form.isEnableShipPrice" :data="form.shopMatchingItems"
                    border style="width: 100%">
                    <el-table-column prop="barcode" align="center" label="商品条码" width="180"></el-table-column>
                    <el-table-column prop="goodsSpec" align="center" label="规格名称" width="180"></el-table-column>
                    <el-table-column prop="unitName" align="center" label="单位" width="180"></el-table-column>
                    <el-table-column prop="address" label="店铺">
                      <template slot-scope="scope">
                        <span style="margin-right:10px" class="annotateGrey" v-if="!scope.row.shopPriceItems || scope.row.shopPriceItems.length == 0">(未设置门店)</span>
                        <el-button type="text" @click="openShopStrategy(scope.$index, 1)">
                          设置门店
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="其他信息">
          <template #cardContent>
            <div class="padd15" style="padding-bottom: 100px">
              <el-form-item prop="saleType" label="售卖方式" v-if="$route.query.type != 'dianCan'">
                <el-radio v-model="form.saleType" label="1">现货</el-radio>
                <el-radio v-model="form.saleType" label="2">预售</el-radio>
              </el-form-item>
              <el-form-item v-if="form.saleType === '2' && $route.query.type != 'dianCan'" label="预售时间"
                style="width: 330px" prop="saleTypeTime">
                <el-date-picker class="inputArrears" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="form.saleTypeTime" type="datetimerange" :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="预售开始时间" end-placeholder="预售结束时间">
                </el-date-picker>
              </el-form-item>
              <el-form-item prop="fetchTypeItems" label="配送方式" v-if="$route.query.type != 'dianCan'">
                <el-checkbox-group v-model="form.fetchTypeItems">
                  <el-checkbox :label="1">门店自提</el-checkbox>
                  <el-checkbox :label="2">同城配送</el-checkbox>
                  <el-checkbox :label="3" v-if="$route.query.type != 'cake'">快递配送</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
               <el-form-item v-if="!['dianCan', 'cake'].includes($route.query.type)" label="统一备货时间">
                <div class="x-f">
                  <div style="margin-right:10px;">下单</div>
                  <el-input style="width:120px;margin-right:10px" size="mini" v-model="form.stockUpHour" type="number"  @input="limitInputlength(form.stockUpHour, form, 'stockUpHour', true,1,false,24)"/>
                  <div style="width:80px;margin-right:10px;">小时后</div>
                  <el-tooltip style="color: black;margin-left: 10px;" content="统一备货时间与门店备货时间是相互叠加关系" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
                </div>
                  <el-table v-if="form.goodsShopStockUpItems && form.goodsShopStockUpItems.length > 0" :data="form.goodsShopStockUpItems"
                    border style="width: 100%">
                    <el-table-column prop="barcode" align="center" label="商品条码" width="180"></el-table-column>
                    <el-table-column prop="goodsSpec" align="center" label="规格名称" width="180"></el-table-column>
                    <el-table-column prop="unitName" align="center" label="单位" width="180"></el-table-column>
                    <el-table-column prop="address" label="店铺">
                      <template slot-scope="scope">
                        <span style="margin-right:10px" class="annotateGrey" v-if="!scope.row.o2omallGoodsShopStockUpItems || scope.row.o2omallGoodsShopStockUpItems.length == 0">(未设置门店)</span>
                        <el-button type="text" @click="openShopStrategy(scope.$index, 2)">
                          设置门店备货时间
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
              </el-form-item>
              <el-form-item v-if="
                  form.fetchTypeItems &&
                  form.fetchTypeItems.includes(3) &&
                  $route.query.type != 'dianCan'
                " prop="goodsExpressFeeType" label="快递运费">
                <div class="x-f" style="margin-bottom: 6px">
                  <el-radio v-model="form.goodsExpressFeeType" :label="1">
                    统一运费：
                  </el-radio>
                  <el-input-number v-model="form.expressFee" :controls="false" :precision="2" :step="0.01" :min="0"
                    :disabled="form.goodsExpressFeeType !== 1" label="请输入统一运费"
                    style="text-align: left"></el-input-number>
                </div>
                <div class="x-f">
                  <el-radio v-model="form.goodsExpressFeeType" :label="2">
                    运费模板：
                  </el-radio>
                  <el-select v-model="form.templateId" clearable :disabled="form.goodsExpressFeeType !== 2"
                    placeholder="请选择运费模板" style="width: 200px">
                    <el-option v-for="item in freightTemplate" :key="item.brandId" :label="item.templateName"
                      :value="item.templateId" />
                  </el-select>
                </div>
                <!-- <el-button type="text" class="marL10" @click="addlist">新建</el-button>
                <el-button type="text">刷新</el-button> -->
              </el-form-item>
              <el-form-item label="限购" v-if="$route.query.type != 'dianCan'">
                <el-checkbox v-model="form.isLimitedQty">限制每人可购买数量</el-checkbox>
                <div v-if="form.isLimitedQty" class="marT10">
                  <div class="x-f">
                    <el-checkbox v-model="form.isLifelongLimited" @change="e => changeLimitedQty(e, 'isCycleLimited')"
                      onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''">终身限购</el-checkbox>
                    <el-input :disabled="!form.isLifelongLimited" style="width: 100px" class="marL10"
                      v-model="form.lifelongLimitedQty" type="number"
                      onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input>
                    <span class="marL10">件</span>
                  </div>
                  <div class="x-f marT10">
                    <el-checkbox v-model="form.isCycleLimited"
                      @change="e => changeLimitedQty(e, 'isLifelongLimited')">按周期限购</el-checkbox>
                    <el-select :disabled="!form.isCycleLimited" style="width: 120px" v-model="form.cycleLimitedType"
                      clearable placeholder="请选择周期" class="marL10">
                      <el-option v-for="item in dict.type.cycle_limited" :key="item.brandId" :label="item.label"
                        :value="item.value" />
                    </el-select>
                    <el-input :disabled="!form.isCycleLimited" style="width: 100px" class="marL10"
                      v-model="form.cycleLimitedQty" type="number"
                      onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input>
                    <span class="marL10">件</span>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="商品详情">
                <div>
                  <el-upload v-show="false" class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
                    :on-success="uploadSuccess" />
                  <quill-editor class="marB20" v-model="form.goodsDetail" ref="QuillEditor" :options="editorOption"
                    @change="onEditorChange($event)">
                  </quill-editor>
                  <!--                                    <QuullEditor :content="form.goodsDetail"></QuullEditor>-->
                </div>
              </el-form-item>
              <!-- <el-form-item>
                <el-button size="small" type="primary" @click="submit"
                  >保存</el-button
                >
              </el-form-item> -->
            </div>
          </template>
        </cardTitleCom>
      </el-form>
    </div>
    <!-- todo -->
    <div class="marL10 previewBox padd15" v-if="false" style="width: calc(30% - 10px)">
      ssss
    </div>

    <!-- 编辑单位弹出框 -->
    <el-dialog title="商品单位设置" :visible.sync="OpenUnit" width="30%" class="goodsUnit" :lock-scroll="false"
      v-dialogDrag="true">
      <div class="top">
        <div class="unitTotal">
          共有<span class="num">{{ unitList.length }}</span>种单位
        </div>
        <div class="addUnit">
          <input type="text" class="ADDinput" v-model="addUnit" placeholder="请输入新单位" />
          <span class="ADDbtn" @click="setUnitList(addUnit)">新增</span>
        </div>
      </div>
      <div class="unitTable">
        <div class="table">
          <div class="item" v-for="(item, index) in unitList" :key="item.dictCode">
            <div class="content">
              <el-input ref="unitFocus" class="unit" type="text" v-model="item.dictValue"
                :readonly="!(readonly === index)" />
              <div class="set">
                <i v-show="showSave === index" class="el-icon-circle-check"
                  @click="updateUnit(item.dictCode, index)"></i>
                <i v-show="!(showSave === index)" class="el-icon-edit" @click="unitGetFocus(index)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 商品属性值列表弹出框 -->
    <selectMachining :OpenMachining.sync="OpenMachining" :machiningData="machiningData" @getMachining="pitchMachining">
    </selectMachining>

    <ShopSpecDialog :shopStrategyItemType="shopStrategyItemType" :show="shopStrategy" :shopItem="shopItem" :shopMatchingItems="shopMatchingItems"
      @closeDia="closeDia" @selectShopOk="selectShopOk"></ShopSpecDialog>
    <!-- 选择商品弹窗 -->
    <SelectGoods :isShopp="true" :OpenGoods.sync="OpenGoods" @getGoodsIds="pitchGoods" />
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import { limitInputlength } from "@/utils/index.js"; //保留几位小数
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import selectMachining from '@/views/components/selectMachining' //属性值选择框组件
import SelectGoods from '@/views/components/SelectGoods/SelectGoods' //选择商品弹窗组件
import goodsSpecItem from '@/views/O2OMall/goods/list/editGood/goodsSpecItem.vue' //规格列表每一项组件
//---------------------
import AccessoryUpload from '@/views/components/accessoryUpload/index.vue'
import UpLoadVideo from '@/components/upLoadVideo/index.vue'
import cardTitleCom from '@/views/components/cardTitleCom'
import Specifications from '@/views/O2OMall/goods/components/specifications.vue'
import CButton from '@/views/components/c-button/index.vue'
import ShopSpecDialog from '@/views/O2OMall/goods/components/shopSpecDialog.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { quillEditor } from 'vue-quill-editor'
//
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import {
  categoryLiStTree,
  deleteVideoById,
  goodsGetDetail,
  synFindImageBasisGoodsToMall,
  synFindUnitMachiningBasisGoodsToMall,
  updateMallGoods
} from '@/api/O2OMall/goods/list'
import {
  categoryLiStTree as dianCanCategoryLiStTree,
  getOrdermealGoodsDetail,
  updateOrdermealGoods,
  dianCanSynFindImageBasisGoodsToMall
} from '@/api/O2OThird/orderMealWxa/goods'
import {
  cakefreeselectCategoryLiStTree,
  cakefreeselectGoodsGetDetail,
  cakefreeselectGoodsUpdate,
  cakeSynFindImageBasisGoodsToMall,
} from '@/api/O2OThird/cakeFreeSelectApp/goods'
import { getData } from '@/utils/public'
import {
  getGoodsUnitDictData,
  getGoodsUnitId,
  listGoods,
  saveGoodsUnitDictData,
  updateGoodsUnitDictData
} from '@/api/goods/goods'
import { templateList } from '@/api/O2OMall/manage/freight'
import OssUpload from '@/components/oss-upload/index.vue'
export default {
  name: 'index',
  dicts: [
    'goods_type', //商品类型
    'pricing_mode', //计价方式
    'App_is_manage_stock', //是否管库存
    'common_status', //商品状态
    'cycle_limited' // 限购周期
  ],
  components: {
    Dialog,
    OssUpload,
    SelectGoods,
    goodsSpecItem,
    selectMachining,
    UpLoadVideo,
    // ------------------
    cardTitleCom,
    // Treeselect,
    AccessoryUpload,
    Specifications,
    CButton,
    ShopSpecDialog,
    quillEditor,
    // QuullEditor
    Treeselect,
    topOperatingButton
  },
  data() {
    return {
      shopStrategyItemType:1,
      //弹窗配置
      dialogOptions: {
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "goods" }),
      },
      loading: true,
      shopItem: {},
      videoInfo: {
        allVideo: [],
        deleteVideo: []
      },
      videoBaseUrl: '', // 已上传的视频路径
      //商品属性值列表弹出框开关
      OpenMachining: false,
      freightTemplate: [], // 运费模板列表
      //选择商品弹窗开关
      OpenGoods: false,
      //标识当前某个规格某个属性组点开的属性弹框
      curSpec: {
        specIndex: undefined,
        mGIndex: undefined
      },
      //根据属性组id获取的属性值数据
      machiningData: [],
      //规格列表下标 从某个规格点出来的选择商品弹窗 后续选完商品根据这个下标赋值
      curSpecIndex: undefined,
      //新增单位
      addUnit: undefined,
      //是否可编辑单位状态
      readonly: true,
      //判断显示隐藏编辑删除多规格单位
      showSave: false,
      //商品图片数组
      fileList: [],
      //品牌数据
      brandData: [],
      //换算单位(参考单位)
      referUnitData: [],
      //字典单位数据
      unitList: [],
      //所有属性组数据
      allMachiningGroupData: [],
      //编辑单位弹出框开关
      OpenUnit: false,
      goodId: null,
      shopStrategy: false, // 规格策略弹框
      shopMatchingItems: {}, // 传入门店策略弹窗的数据
      shopStrategyItem: 0, // 设置门店策略的规格下标
      form: {
        goodsExpressFeeType: null, // 快递运费防暑
        basicGoodsUnit: [], // 基础资料内多规格
        goodsId: undefined, //商品id
        fetchTypeItems: [1, 2], // 配送方式
        goodsImages: [], //商品图片
        goodsName: undefined, //商品名称
        goodsType: undefined, //商品类型
        categoryId: undefined, //商品分类
        goodsModel: undefined, //商品型号
        goodsNo: undefined, //商品编号
        barcode: undefined, //条码
        sortNo: undefined, //排序
        taxRate: undefined, //税率
        unitId: 0, //基本单位id
        unitName: undefined, //基本单位名称
        goodsSpec: undefined, //商品规格
        qualityDays: undefined, //保质期天数
        weight: undefined, //重量
        // purPrice: undefined, //进价
        salePrice: undefined, //售价
        pricingMode: '0', //计价方式
        isManageStock: '', //是否管库存
        status: '0', //状态
        brandId: undefined, //品牌
        isEnableBargaining: false, //允许议价
        isEnableDiscount: false, //允许折扣
        isEnableGive: false, //允许赠送
        isEnablePurchase: false, //允许采购
        isEnableSale: false, //允许销售
        isEnableIntegral: false, //允许积分
        saleType: '1', //售卖方式 1：现货; 2: 预售
        saleTypeTime: [],
        templateId: '', // 运费模板
        isLimitedQty: false, // 是否限购
        isLifelongLimited: false, // 是否终身限购
        lifelongLimitedQty: '', //终身限购数量
        isCycleLimited: false, // 是否周期限购
        cycleLimitedQty: '', // 周期限购件数
        cycleLimitedType: '', // 限购周期
        goodsDetail: '', // 商品详情（富文本）
        liningPrice: undefined, // 划线价格
        sellingPoints: '', // 卖点
        isEnableShopPrice: false, // 是否启用门店价格策略
        isEnableShipPrice: false, // 蛋糕 是否启用门店价格策略
        video: {
          format: '',
          height: 0,
          accessUrl: '',
          size: 0,
          videoId: '',
          videoName: '',
          width: 0
        }, // 视频信息
        shopMatchingItems: [
          {
            barcode: '',
            goodsId: '',
            goodsName: '',
            goodsSpec: '',
            unitName: '',
            shopPriceItems: []
          }
        ], // 门店价格策略
        //规格列表
        goodsUnit: [
          {
            //规格信息
            goodsUnits: [
              {
                barcode: undefined, //条码
                goodsSpec: undefined, //规格
                purPrice: undefined, //单位进价
                salePrice: undefined, //单位售价
                referUnitAmount: undefined, //参考单位比例系数
                referUnitId: undefined, //参考单位id
                unitId: undefined, //多规格单位id
                unitName: undefined, //单位名称
                stockQuantity: undefined // 库存数
              }
            ],
            //属性数组
            machiningGroups: [],
            //搭配商品
            collocationItems: []
          }
        ],
        goodsShopStockUpItems: [], // 商品门店库存
      },
      // 商品表单校验
      rules: {
        categoryId: [
          { required: true, message: '请选择商品分类', trigger: 'change' }
        ],
        saleType: [
          { required: true, message: '请选择售卖方式', trigger: 'change' }
        ],
        saleTypeTime: [
          { required: true, message: '请选择预售时间', trigger: 'change' }
        ],
        fetchTypeItems: [
          { required: true, message: '请选择配送方式', trigger: 'change' }
        ],
        goodsExpressFeeType: [
          { required: true, message: '请选择运费设置', trigger: 'change' }
        ]
        // isManageStock: [
        //     { required: true, message: '请选择是否管库存', trigger: 'change' }
        // ],
        // pricingMode: [
        //     { required: true, message: '请选择计价方式', trigger: 'change' }
        // ],
        // purPrice: [
        //     { required: true, message: '请输入商品进价', trigger: 'blur' }
        // ],
        // salePrice: [
        //     { required: true, message: '请输入商品售价', trigger: 'blur' }
        // ],
        // unitName: [
        //     { required: true, message: '请选择基本单位', trigger: 'blur' }
        // ],
        // liningPrice: [
        //     { required: true, message: '请输入划线价格', trigger: 'blur' }
        // ]
      },
      // -------------------------------------------------
      // radio: 1,
      // checkList: [],
      // value: '',

      operationMod: 1, // 运营模板
      checked: true,
      // 分类树选项
      categoryTreeOptions: [],

      // 富文本编辑器配置
      // 富文本编辑器配置
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/open/oss/file/upload', // 服务器上传地址
      editorOption: {
        placeholder: '请输入商品详情',
        modules: {
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false
          },
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              ['blockquote', 'code-block'], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除文本格式
              ['image', 'video'] // 链接、图片、视频
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用element的图片上传组件
                  document.querySelector('.avatar-uploader input').click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          }
        }
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        console.log('新规格：', newVal)
      }
    },
    deep: true
  },
  created() {
    this.loading = true
    this.getselectData()
    this.getGoodsUnitDictList()
    this.getMachiningData()
    if (this.$route.query.type != 'dianCan') {
      this.getFreightTemplate()
    }
    this.goodId = this.$route.query.goodId
    console.log('编辑商品信息：', this.goodId)
    console.log('编辑商品信息2：', this.$route.query.goodId)
    this.getGoodsDetail()
    this.getTreeselect() // 弹框树形下拉选择
    //     ---------------------------------
    // this.getTreeselect()
  },
  methods: {
    sortChange(value){
      const urlOrder = value.map((item, index) => ({ url: item.url, index }));
      function getSortOrder(accessUrl) {
        return urlOrder.findIndex(item => item.url === accessUrl);
      }
      this.form.goodsImages.sort((a, b) => getSortOrder((a.imageUrl)) - getSortOrder(b.imageUrl));
    },
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          break;
        case "openShop":
          this.dialogOptions = {
            title: "选择商品",
            width: 1250,
            show: false,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "goods" }),
          }
          this.dialogOptions.formData.table.echoChecked =this.form.goodsShopStockUpItems;
          break;
        default:
          break;
      }
    },
    limitInputlength,
    synchronousImg () {
      console.log('synchronousImg');
      this.$modal.confirm('是否同步"基本资料—商品资料"的图片').then(async () => {
        let url = this.$route.query.type == 'cake'?  cakeSynFindImageBasisGoodsToMall : this.$route.query.type == 'dianCan'? dianCanSynFindImageBasisGoodsToMall : synFindImageBasisGoodsToMall
        url(
          {
            categoryId: this.form.categoryId,
            goodsIds: [this.form.goodsId]
          }
        ).then((res) => {
          this.$message.success('同步成功')
          this.getGoodsDetail()
        }).catch((err) => {
          this.$message.error('同步失败')
        });
      })
    },
    synchronousUnit () {
      this.$modal.confirm('是否同步"基本资料—商品资料"的规格和属性').then(async () => {
        synFindUnitMachiningBasisGoodsToMall(
          {
            categoryId: this.form.categoryId,
            goodsIds: [this.form.goodsId]
          }
        ).then((res) => {
          this.$message.success('同步成功')
          this.getGoodsDetail()
        }).catch((err) => {
          this.$message.error('同步失败')
        });
      })
    },
    changeOss(val) {
      this.form.video.fileName = val.fileName
      this.form.video.size = val.fileSize / 1024
      this.form.video.format = val.fileType
    },
    getQuit() {
      // 关闭当前tab页签，打开新页签
      const path = this.$route.query.type == 'dianCan' ? '/O2OThird/orderMealWxa/goods' : this.$route.query.type == 'cake' ? '/O2OThird/cakeFreeSelectApp/goods' : '/O2OMall/goods/list'
      this.$tab.closeOpenPage({ path });
    },
    changeLimitedQty(e, type) {
      if (e) {
        this.form[type] = false
      }
    },
    uploadSuccess(res) {
      // 获取富文本组件实例
      console.log('uploadSuccess', res)
      try {
        let quill = (this.$refs.QuillEditor[0] || this.$refs.QuillEditor).quill
        // 如果上传成功
        if (res) {
          // 获取光标所在位置
          let length = quill.getSelection().index
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, 'image', res.data.accessUrl)
          // 调整光标到最后
          quill.setSelection(length + 1)
          return true
        }
      } catch (error) { }
      this.$message.error('图片插入失败！')
    },
    select(e) {
      if (e.disableBranchNodes) {
        return false
      }
    },
    /** 查询分类下拉树结构 */
    getTreeselect() {
      let url =
        this.$route.query.type == 'dianCan'
          ? dianCanCategoryLiStTree
          : this.$route.query.type == 'cake' ? cakefreeselectCategoryLiStTree : categoryLiStTree
      url({ isDisableRoot: true }).then(res => {
        if (res.data?.[0]?.children) {
          let children = []
          children = res.data[0].children.map(x => ({
            ...x,
            children: x.children?.length ? x.children : undefined
          }))
          this.categoryTreeOptions = children
        }
      })
    },
    submit() {
      this.$refs['form'].validate(async valid => {
        //表单校验
        if (valid) {
          if (this.form.sortNo&&this.form.sortNo<=0)return this.$message.error('排序不能小于等于0')
          const success = () => {
            //过滤商品规格空属性
            this.form.goodsUnit.map(item => {
              item.machiningGroups = item.machiningGroups.filter(
                v => v.machiningGroupId
              )
            })

            //校验选中属性组是否选了属性值
            let machiningPitch = this.form.goodsUnit.every(item => {
              let bool = item.machiningGroups.some(v => {
                if (v.machiningGroupId) v.machiningItems ? true : false
              })
              return bool
            })
            if (machiningPitch) this.$message.error('请选择属性值')
            this.form.goodsImages = this.form.goodsImages.map((x) => ({ ...x, imageUrl: x.accessUrl? x.accessUrl : x.imageUrl, }))
            console.log(this.form, 'form');
            // return
            let url =
              this.$route.query.type == 'dianCan'
                ? updateOrdermealGoods
                : this.$route.query.type == 'cake' ? cakefreeselectGoodsUpdate : updateMallGoods
            url(this.form).then(res => {
              this.$message.success('保存成功')
            })
          }
          if (this.form.saleTypeTime?.length === 2) {
            this.form.saleBegTime = this.form.saleTypeTime[0]
            this.form.saleEndTime = this.form.saleTypeTime[1]
          }
          if (this.form.isEnableShopPrice) {
            // 门店价格策略
            const item = this.form.shopMatchingItems.find(x =>
              Boolean(x.shopPriceItems?.find(y => y.isOnline))
            )
            if (!item) {
              this.$confirm(
                '该商品规格没上架到门店将不会展示，是否继续保存?',
                '提示',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }
              ).then(() => {
                success()
              })
            } else {
              success()
            }
          } else {
            // 找到一个上架的
            const item = this.form.goodsUnit.find(x =>
              Boolean(x.goodsUnits?.find(y => y.status === 0))
            )
            if (!item) {
              return this.$message.error('最低需上架一个商品规格！')
            }
            success()
          }
        } else {
          this.$message.error('请把带有红星的必填项输入完整')
        }
      })
    },
    //添加单位字典
    setUnitList(addUnit) {
      if (addUnit.trim()) {
        //判断新增的单位是否跟单位数据重复
        const bool = this.unitList.some(item => item.dictValue === addUnit)
        if (!bool) {
          saveGoodsUnitDictData(addUnit).then(response => {
            this.getGoodsUnitDictList()
          })
        } else {
          this.$message.error('单位名称重复,请重新输入!')
        }
      } else {
        this.$message.error('请输入单位名称')
      }
      this.addUnit = ''
    },
    //选中的属性值数据
    pitchMachining(data) {
      let machiningData =
        this.form.goodsUnit[this.curSpec.specIndex].machiningGroups[
        this.curSpec.mGIndex
        ]
      //当属性值数组不存在时,初始化一个空数组
      if (!machiningData.machiningItems) {
        this.$set(machiningData, 'machiningItems', [])
      } else {
        //当属性数组有值时,过滤掉重复的属性值
        for (let i = 0; machiningData.machiningItems.length > i; i++) {
          data = data.filter(
            v => v.machiningId !== machiningData.machiningItems[i].machiningId
          )
        }
      }
      //选中的属性值追加在后面
      machiningData.machiningItems.push(...data)
    },
    //编辑字典单位事件
    updateUnit(dictCode, index) {
      updateGoodsUnitDictData({
        dictCode: dictCode,
        dictLabel: this.unitList[`${index}`].dictValue
      }).then(response => {
        this.$message.success('编辑单位成功')
        this.showSave = false
        this.readonly = false
        this.getGoodsUnitDictList()
      })
    },
    //编辑字典单位获得焦点事件
    unitGetFocus(index) {
      this.showSave = index
      this.readonly = index
      this.$nextTick(() => {
        this.$refs.unitFocus[`${index}`].focus()
      })
    },
    //获取商品属性数据
    async getMachiningData() {
      this.allMachiningGroupData = await getData('goodsMachining') //商品属性数据
    },
    //规格列表同步基本规格信息
    basicSpec(event, name) {
      if (name === 'barcode') {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], 'barcode', event)
      }
      if (name === 'purPrice') {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], 'purPrice', event)
      }
      if (name === 'salePrice') {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], 'salePrice', event)
      }
      if (name === 'goodsSpec') {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], 'goodsSpec', event)
      }
      if (name === 'unitName') {
        this.$set(this.form.goodsUnit[0].goodsUnits[0], 'unitName', event)
        this.$set(this.form.goodsUnit[0].goodsUnits[0], 'unitId', 0)
      }
    },
    //新增/修改表单tree下拉组件单独校验事件
    treeVerify() {
      this.$nextTick(() => {
        this.$refs.form.validateField('categoryId')
      })
    },
    //treeSelect结构属性转换
    normalizer1(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    //treeSelect结构属性转换
    normalizer(node) {
      if (node && node.children.length < 1) {
        return
      }
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    //获取下拉框数据
    async getselectData() {
      this.brandData = await getData('goodsBrand') //商品品牌数据
    },
    //获取字典单位数据
    getGoodsUnitDictList() {
      getGoodsUnitDictData().then(response => {
        this.unitList = response.data
      })
    },
    //点击设置单位
    setUnit() {
      this.OpenUnit = true
    },
    //操作商品属性组(新增/删除) type新增/删除  specListIndex 规则列表下标   MIndex 属性组下标
    handleAddMachiningGroup({ type, specListIndex, MGIndex }) {
      let machiningGroupsData =
        this.form.goodsUnit[specListIndex].machiningGroups
      if (type === 'add') {
        machiningGroupsData.push({})
      } else {
        //删除属性组
        machiningGroupsData.splice(MGIndex, 1)
      }
    },
    //操作商品属性值(新增/删除) type新增/删除  specListIndex 规则列表下标 MGIndex 属性组下标  MIndex 属性下标  id 属性组id
    handleAddMachining({ type, specListIndex, MGIndex, MIndex, id }) {
      if (type === 'add') {
        console.log(id)
        let machiningGroupId = id //传过来的属性分组id 获取属性值数据
        this.allMachiningGroupData.forEach(v => {
          if (v.machiningGroupId === machiningGroupId) {
            this.curSpec.specIndex = specListIndex //当前规格下标
            this.curSpec.mGIndex = MGIndex //当前属性组下标
            this.machiningData = v.machiningItems //当前属性组的属性值
            this.OpenMachining = true
          }
        })
      } else {
        //删除属性值
        this.form.goodsUnit[specListIndex].machiningGroups[
          MGIndex
        ].machiningItems.splice(MIndex, 1)
      }
    },
    //删除商品规格
    handleDelGoodsSpec(index) {
      console.log(index)
      //判断该商品规格是否被引用 已被引用提醒用户先删除引用的规格
      let bool = this.form.goodsUnit.some(
        v =>
          v.goodsUnits[0].referUnitId ===
          this.form.goodsUnit[index].goodsUnits[0].unitId
      )
      console.log(bool)
      if (bool) {
        this.$message.warning('该商品规格已被引用,请先删除被引用商品规格')
      } else {
        //删除多规格
        this.form.goodsUnit.splice(index, 1)
      }
    },
    //刷新换算单位数据  index当前规格列表下标
    refreshReferUnitData(index) {
      for (let i = 0; this.form.goodsUnit.length > i; i++) {
        //去掉规格列表当前行的单位
        if (i !== index) {
          if (this.form.goodsUnit[i].goodsUnits[0].unitName) {
            //过滤掉重复的单位
            let bool = this.referUnitData.some(
              v => v.referUnitId === this.form.goodsUnit[i].goodsUnits[0].unitId
            )
            if (!bool) {
              this.referUnitData.push({
                referUnitId: this.form.goodsUnit[i].goodsUnits[0].unitId,
                unitName: this.form.goodsUnit[i].goodsUnits[0].unitName
              })
            }
          }
        } else if (i === 0) {
          this.$set(this.referUnitData, 0, {})
          this.referUnitData[0].referUnitId = 0
          this.referUnitData[0].unitName = this.form.unitName
        }
      }
    },
    //规格列表搭配商品 打开选择商品弹窗  index当前规格下标
    openSelectGoods(index) {
      this.curSpecIndex = index
      this.OpenGoods = true
    },
    //操作搭配商品表格 type(增加行/减少行) specIndex规格列表下标  index当前表格行下标
    handleCollocationTableRow({ type, specIndex, index }) {
      if (type === 'push') {
        this.form.goodsUnit[specIndex].collocationItems.push({})
      } else {
        this.form.goodsUnit[specIndex].collocationItems.splice(index, 1)
      }
    },
    //搭配商品表格 商品编码键盘回车事件  val输入的值 specIndex规格列表下标  index当前表格行下标
    collocationTableKeyup({ val, specIndex, index }) {
      console.log(val)
      //判断输入值是否为空
      if (val) {
        listGoods({ goodsNo: val }).then(async response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(
              this.form.goodsUnit[specIndex].collocationItems,
              index,
              response.rows[0]
            )
          } else {
            this.OpenGoods = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        this.OpenGoods = true
      }
    },
    // 设置门店价格策略列表数据
    setShopPriceItems(arr) {
      this.form.shopMatchingItems = arr.map(x => {
        const data =
          this.form.shopMatchingItems?.find(
            y => x.unitId === y.unitId && x.barcode === y.barcode
          )?.shopPriceItems || []
        return {
          goodsId: this.form.goodsId,
          goodsName: this.form.goodsName,
          barcode: x.barcode,
          goodsSpec: x.goodsSpec,
          unitId: x.unitId,
          unitName: x.unitName,
          salePrice: x.salePrice,
          liningPrice: x.liningPrice,
          stockQuantity: x.stockQuantity,
          shopPriceItems: data.map(y => ({
            ...y,
            salePrice: y.salePrice,
            liningPrice: y.liningPrice,
            stockQuantity: y.stockQuantity
          }))
        }
      })
      this.form.goodsShopStockUpItems = arr.map(x => {
        const data = this.form.goodsShopStockUpItems?.find(
            y => x.unitId === y.unitId && x.barcode === y.barcode
          )?.o2omallGoodsShopStockUpItems || []
        return {
          goodsId: this.form.goodsId,
          goodsName: this.form.goodsName,
          barcode: x.barcode,
          goodsSpec: x.goodsSpec,
          unitId: x.unitId,
          unitName: x.unitName,
          o2omallGoodsShopStockUpItems: data
        }
      })
    },
    // 打开门店策略弹框
    openShopStrategy(index, type = 1) {
      // shopItem
      this.shopStrategyItemType = type
      this.shopStrategyItem = index
      this.shopMatchingItems = type == 1 ? this.form.shopMatchingItems[this.shopStrategyItem] : this.form.goodsShopStockUpItems[this.shopStrategyItem]
      this.shopStrategy = true
      console.log('shopStrategyItemType',this.shopStrategyItemType);
    },
    // // 关闭门店价格策略
    closeDia() {
      this.shopStrategy = false
    },
    // 关闭门店价格策略弹框并拿到数据
    selectShopOk(data) {
      console.log('关闭前：', data)
      if (this.shopStrategyItemType == 1) {
        this.form.shopMatchingItems[this.shopStrategyItem].shopPriceItems = data
      }else {
        this.form.goodsShopStockUpItems[this.shopStrategyItem].o2omallGoodsShopStockUpItems = data
      }
      this.closeDia()
      console.log('关闭：', this.form.shopMatchingItems)
    },
    //新建商品规格
    handleAddGoodsSpec() {
      //标识是否输入完整基本信息
      let bool = true
      //校验是否把基本信息必填项输入完整
      if (this.form.goodsUnit.length === 1) {
        this.$refs['form'].validate(async valid => {
          //表单校验
          valid ? (bool = true) : (bool = false)
        })
      }
      if (!bool) return this.$message.warning('请把带红星的基本信息输入完整')
      //新增一个多规格
      //获取一个标识的多规格单位id
      getGoodsUnitId().then(response => {
        this.form.goodsUnit.push({
          goodsUnits: [{ unitId: response.id }],
          machiningGroups: [],
          collocationItems: []
        })
      })
      //新增规格里属性数据
      this.getMachiningData()
    },
    //获取上传的图片数据
    getGoodsUrls(data) {
      console.log('上传图片：', data)
      this.form.goodsImages.push(data)
    },
    //删除上传的图片
    delGoodsUrl(fileData, url) {
      console.log('删除filePath:', fileData, url)
      console.log('this.form.goodsImages:', this.form.goodsImages)
      this.form.goodsImages = this.form.goodsImages.filter((e) => e.imageUrl != url)
      // this.form.goodsImages.forEach((item, index) => {
      //   if (item.fileName == fileData.fileName) {
      //     this.form.goodsImages.splice(index, 1)
      //   }
      // })
      console.log('已删除：', this.form.goodsImages)
    },
    //  上传视频改变
    getVideo(videoData) {
      console.log('上传视频完成：', videoData, this.form.video)
      this.form.video = videoData
    },
    // 删除视频
    deleteVideo(videoId) {
      console.log('删除视频', videoId)
      deleteVideoById({ videoId: videoId }).then(res => {
        this.form.video = {}
      })
    },
    //选择商品弹窗 选中的商品
    pitchGoods(data) {
      // 过滤表格里空行
      this.form.goodsUnit[this.curSpecIndex].collocationItems =
        this.form.goodsUnit[this.curSpecIndex].collocationItems.filter(
          v => v.goodsId
        )
      this.form.goodsUnit[this.curSpecIndex].collocationItems.push(...data)
    },
    // 新增运费模板按钮
    addlist() {
      this.$router.push({
        name: 'AddFreightTemp'
      })
    },
    async getFreightTemplate() {
      const res = await templateList()
      this.freightTemplate = res.rows
    },
    async getGoodsDetail() {
      this.loading = true
      try {
        let url =
          this.$route.query.type == 'dianCan'
            ? getOrdermealGoodsDetail
            : this.$route.query.type == 'cake' ? cakefreeselectGoodsGetDetail : goodsGetDetail
        const res = await url({ goodsId: this.goodId })
        res.data.saleTypeTime = []
        if (res.data.saleBegTime && res.data.saleEndTime) {
          res.data.saleTypeTime = [res.data.saleBegTime, res.data.saleEndTime]
        }
        res.data.isEnableShopPrice = Boolean(res.data.isEnableShopPrice)
        this.form = res.data
        //商品规格列表 搭配商品表格数组初始化为空数组
        this.form.goodsUnit = this.form.goodsUnit.map(v => {
          if (!v.collocationItems) v.collocationItems = []
          if (!v.machiningGroups) v.machiningGroups = []
          return v
        })
        this.fileList = []
        if (this.form.goodsImages) {
          this.form.goodsImages.forEach(item => {
            this.fileList.push({
              url: item.imageUrl? item.imageUrl : item.accessUrl,
              domain: item.domain,
              fileName: item.fileName,
              filePath: item.filePath,
              fileSize: item.fileSize
            })
          })
          this.form.goodsImages = tthis.form.goodsImages.map((x) => ({ ...x, imageUrl: x.imageUrl? x.imageUrl : x.accessUrl, }))
        } else {
          this.form.goodsImages = []
        }
        console.log('获取详情：', this.form)
      } catch (error) { }
      this.loading = false
    },

    // ----------------------------------------------------------------------

    //商品搭配表格
    row(name, index) {
      if (name === 'push') {
        this.form.details.splice(index + 1, 0, {})
      } else {
        if (this.form.details.length <= 1) return
        this.form.details.splice(index, 1)
      }
    },
    //监听数据变化失去焦点时  滚动条的位置
    watchScroll() {
      if (this.scrollLeft) this.savescroll()
    },
    //选择商品方法
    selectGoods() {
      this.OpenGoods = true
    },
    //     富文本-==============================
    // 内容改变事件
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', quill, html, text)
      this.content = html
    }
  }
}
</script>

<style lang="scss" scoped>
.inputW200 {
  width: 200px;
}

.container {
  padding: 10px;
  background: #e9e9e9;
  flex: auto;
  min-width: 0;
  padding-top: 40px;

  .formBox {
    width: 75%;

    .el-form-item {
      margin-bottom: 20px;

      //宽度33%
      &.w33 {
        width: 50%;

        .el-input,
        .el-select,
        .vue-treeselect {
          width: 70%;
        }
      }

      //宽度60%
      &.w60 {
        width: 60%;
      }

      //规格列表
      &.specList {
        width: 100%;
      }

      .el-input,
      .vue-treeselect,
      .el-select {
        width: 100%;
      }
    }
  }

  .previewBox {
    width: calc(25% - 10px);
    background: #ffffff;
  }
}

//字典单位弹出框样式
.goodsUnit {
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 40px;
    line-height: 40px;

    .unitTotal {
      .num {
        color: #0088dd;
        margin: 0 2px;
      }
    }

    .addUnit {
      width: 40%;
      border: 1px solid #0096cb;
      border-radius: 5px;

      .ADDinput {
        width: 70%;
        border: none;
        outline: none;
        text-align: center;
      }

      .ADDbtn {
        display: inline-block;
        text-align: center;
        background-color: #0088dd;
        color: #fff;
        width: 30%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  .unitTable {
    height: 33vh;
    overflow: auto;
    border-bottom: 1px solid #ccc;
    border: 1px solid #ccc;
    border-radius: 5px;

    // 滚动条的样式,宽高分别对应横竖滚动条的尺寸
    &::-webkit-scrollbar {
      width: 3px;
    }

    // 滚动条里面默认的小方块,自定义样式
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 2px;
    }

    // 滚动条里面的轨道
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    .table {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .item {
        margin: 5px 5px;
        width: 23%;
        height: 50px;
        line-height: 50px;
        border: 1px solid #ccc;
        border-radius: 5px;
        display: flex;

        .content {
          display: flex;
          width: 100%;
          padding: 0 5px;
          align-items: center;

          .unit {
            width: 100%;
            flex: 7;
            padding-right: 5px;
            border: none;
            outline: none;
          }

          .set {
            flex: 3;
            cursor: pointer;
            display: flex;

            i {
              margin-right: 5px;
              color: #0088dd;
            }
          }
        }
      }
    }
  }
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .ql-editor {
  height: 400px;
}
</style>
